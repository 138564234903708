









































































































































































































































































































































































































import Vue from "vue";
import HeroText from "@/components/modules/HeroText.vue";
import HighlightStrip from "@/components/modules/HighlightStrip.vue";
import StickyBanner from "@/components/modules/StickyBanner.vue";
import Card from "@/components/modules/Card.vue";
import {IOrderRequest} from "@/store/types/Order";
import {
  IDocumentsRequest,
  IDocument,
  IDocumentRequest,
} from "@/store/types/Document";
import {mapState, mapGetters, mapMutations, mapActions} from "vuex";
import queryString from "query-string";
import {AxiosResponse} from "axios";
import "@/extensions/StringExtensions.ts";
import ViewportHelper from "@/helpers/ViewportHelper";
import {getRouteName} from "@/routeConfig";
import i18n from '@/i18n/index';
import EnvironmentService from '@/services/EnvironmentService';
import RhSlider from "@/components/elements/RhSlider.vue";
import {OrderView} from "@/store/types/OrderView";
import {featureFlags} from "@/store/feature-flag.store";
import {
  OrderDetailResponseOrderContactData,
  OrderDetailResponseOrderService,
  OrderDetailResponseOrderShipment
} from "@/services/types/OrderDetailResponse";
import moment from "moment/moment";
import { TrackingMapVisibilityService } from "@/services/TrackingMapVisibilityService";
import {translateOrderStatus} from "@/store";

const viewportHelper = new ViewportHelper();

export default Vue.extend({
  components: {
    RhSlider,
    HeroText,
    HighlightStrip,
    Card,
    StickyBanner,
  },
  data() {
    return {
      isLoadingDocument: false,
      isLoadingOrder: false,
      documents: [] as IDocument[],
      pageComplete: false,
      clientsExcludedFromTrustPilot: EnvironmentService.applicationConfiguration.clientsExcludedFromTrustPilot.split(','),
      hideBanner: false,
      trackingMapVisibilityService: new TrackingMapVisibilityService(this.$store)
    };
  },
  mounted() {
    this.initStickyBottom();
    this.requestOrderByQuery();
  },
  computed: {
    ...mapState([
      "isLoggedIn",
      "service",
      "order",
      "notificationDescription",
      "language",
      "stickyBottom",
    ]),
    ...mapGetters([
      "getMappedClientName",
      "isIkeaClient",
      "getOrder",
      "getLogoName",
      "allowTracking"
    ]),
    orderView(): OrderView {
      if (this.isLoadingOrder) {
        return {} as OrderView;
      }
      // we need the checks for undefined, in case the order is not loaded yet when opened via deepLink
        const cancelledStatus = 'Cancelled';
        const completedStatus = 'Fulfilled';
        const deliveries: OrderDetailResponseOrderShipment[] = this.order.shipments? this.order.shipments.filter((s: OrderDetailResponseOrderShipment) => s.type === 'Delivery'): [];
        const pickups: OrderDetailResponseOrderShipment[] = this.order.shipments? this.order.shipments.filter((s: OrderDetailResponseOrderShipment) => s.type === 'Pickup'): [];
        const services: OrderDetailResponseOrderService[] = this.order.services ? this.order.services: [];
        const contactData: OrderDetailResponseOrderContactData = this.order.contactData? this.order.contactData: {};
        return {
          allowSelfScheduling: this.order.allowSelfScheduling,
          noDeliveryWindow: !this.order.deliveryDate && (this.order.currentStatus === cancelledStatus || !this.order.allowSelfScheduling ||
            !this.order.isSchedulingStatusReached),
          isCompleted: this.order.currentStatus === completedStatus,
          isCancelled: this.order.currentStatus === cancelledStatus,
          customerName: contactData.customerName,
          orderNumber: this.order.orderNumber,
          clientName: this.order.clientDisplayName,
          currentStatus: translateOrderStatus(this.order.currentStatus),
          deliveryDate: this.order.deliveryDate,
          deliveryTimeWindowFrom: moment(this.order.deliveryTimeWindowFrom).format("HH:mm"),
          deliveryTimeWindowTo: moment(this.order.deliveryTimeWindowTo).format("HH:mm"),
          address: `${contactData.orderAddressStreet} ${contactData.orderAddressHouseNumber}`,
          postCode: contactData.orderAddressPostCode,
          city: contactData.orderAddressCity,
          phone: contactData.orderAddressPhone,
          email: contactData.orderAddressEmail,
          weight: this.order.weight,
          volume: this.order.volume,
          deliveryArticleCount: featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_SHIPMENT_COUNT ? this.order.deliveryArticleCount : deliveries.reduce((total, value) => {
            total += value.orderedQuantity;
            return total;
          }, 0),
          deliveryArticles: deliveries.map(d => `${d.orderedQuantity} x ${d.productName}`),
          pickupArticleCount: pickups.reduce((total, value) => {
            total += value.orderedQuantity;
            return total;
          }, 0),
          pickupArticles: pickups.map(p => `${p.orderedQuantity} x ${p.productName}`),
          servicesCount: services.reduce((total, value) => {
            total += value.deliveryQuantity;
            return total;
          }, 0),
          serviceArticles: services.map(s => `${s.deliveryQuantity} x ${s.name}`),
          isExcludedFromTrustPilot: this.clientsExcludedFromTrustPilot.includes(this.order.clientKey),
        }
    }
  },
  created() {
    window.addEventListener("resize", this.resizing);
    window.addEventListener("scroll", this.scrolling);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizing);
    window.removeEventListener("scroll", this.scrolling);
  },
  methods: {
    openLink() {
      window.open("http://cloud.cjm.cls-rhenus.com/rhdaktuelleinformationen");
    },
    getLinkToTracking: function () {
      return {name: getRouteName("tracking")};
    },
    getLinkToAppointmentSelection: function () {
      return {name: getRouteName("appointmentSelection")};
    },
    requestOrderByQuery() {
      const query = queryString.parse(window.location.search);
      const hasOwnPropertyO: boolean = Object.prototype.hasOwnProperty.call(
        query,
        "o"
      );
      const hasOwnPropertyM: boolean = Object.prototype.hasOwnProperty.call(
        query,
        "m"
      );
      if (hasOwnPropertyO && hasOwnPropertyM) {
        const requestQuery = {
          externalOrderNumber: query.o,
          hash: query.m,
          encryptionLoginType: true,
        } as IOrderRequest;

        // handle automatic request for order details
        this.isLoadingOrder = true;
        this.requestOrderDetails(requestQuery)
          .then(async (loginSuccessful: boolean) => {
            if (loginSuccessful) {
              this.LOGIN();           
              await this.trackingMapVisibilityService.updateDataForTrackingMap();
              
              await Promise.all([
                this.requestDocumentsByOrderNumber(),
                this.bookableServices()
              ]);
              this.isLoadingOrder = false;
            } else {
              this.$navigateTo("error");
            }
          })
          .catch(() => {
            this.$navigateTo("error");
          });
      } else {
        // handle what happens if the user doesn't have the neccessary URL parameters on
        if (this.isLoggedIn) {
          this.requestDocumentsByOrderNumber();
          this.bookableServices();
        } else {
          this.$navigateTo("error");
        }
      }
    },
    bookableServices() {
      return this.requestBookableServices();
    },
    displayValuationLogo() {
      return i18n.locale !== "cz";
    },
    requestDocumentsByOrderNumber() {
      const requestQuery = {
        externalOrderNumber: this.getOrder.externalOrderNumber,
        internalOrderNumber: this.getOrder.internalOrderNumber,
      } as IDocumentsRequest;

      return this.requestDocuments(requestQuery)
        .then((response: IDocument[]) => {
          this.documents = response;
        })
        .catch(() => {
        })
        .finally(() => {
          this.pageComplete = true;
        });
    },
    openDocument(documentUri: string, documentType: string) {
      this.isLoadingDocument = true;
      const requestQuery = {
        url: documentUri,
      } as IDocumentRequest;

      this.requestDocument(requestQuery)
        .then((response: AxiosResponse<BlobPart>) => {
          //Create a Blob from the PDF Stream
          if (response.status === 200) {
            this.isLoadingDocument = false;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(response.data);
            link.download = `${documentType}.pdf`;
            link.click();
          }
        })
        .catch(() => {
          this.isLoadingDocument = false;
          this.$navigateTo("error");
        });
    },
    resizing() {
      this.scrolling();
    },
    scrolling() {
      const bannerHighlightVueComponent = this.$refs.bannerHighlight as Vue;
      if (!bannerHighlightVueComponent) {
        return;
      }
      const bannerHighlight = bannerHighlightVueComponent.$el as HTMLElement;
      this.hideBanner = viewportHelper.isInViewport(bannerHighlight, 20);

      if (this.hideBanner) return;
      this.updateStickyBottom();
    },
    ...mapMutations(["LOGIN"]),
    ...mapActions([
      "requestOrderDetails",
      "requestDocuments",
      "requestDocument",
      "requestBookableServices",
      "initStickyBottom",
      "updateStickyBottom",
    ]),
  },
});
