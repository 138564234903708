import Vue from 'vue';
import Vuex from 'vuex';
import {IFundamentalData, IState} from '@/store/types/IState';
import {ILatLng, IOrder, IOrderRequest, IOrderSchedulability} from '@/store/types/Order';
import {AxiosResponse} from 'axios';
import {
    IAppointmentsRequest,
    IIcsFileData,
    ISetAppointmentRequest
} from '@/store/types/Appointment';
import {IDocument, IDocumentData, IDocumentRequest, IDocumentsRequest} from '@/store/types/Document';
import {Appointment} from '@/components/modules/types/Calendar';
import moment from 'moment';
import {IBookableService, IBookableServicesApi, IBookedService, IBookServicesApi, IBookServicesRequest, IPrice} from '@/store/types/Service';
import '@/extensions/StringExtensions.ts';
import i18n, {resolveTmsLocale} from '@/i18n/index';
import CalendarHelper from '@/helpers/CalendarHelper';
import BookableServiceFilter from '@/helpers/BookableServiceFilter';
import ViewportHelper from '@/helpers/ViewportHelper';
import VuexPersist from 'vuex-persist';
import {orderDetailService} from '@/services/OrderDetailService';
import {featureFlags} from "@/store/feature-flag.store";
import {legacyApiService} from "@/services/ApiService";
import { appointmentService } from '@/services/AppointmentService';
import {OrderDetailResponseOrder} from "@/services/types/OrderDetailResponse";
import { bookableServicesService } from '@/services/BookableServiceService';
import { contactFormService } from '@/services/ContactFormService';
import { trackingService } from '@/services/TrackingService';
import { DeliveryTruckMapData } from '@/services/types/TrackingMapData';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'vuex-state',
  storage: window.sessionStorage,  
})
const calendarHelper = new CalendarHelper();
const bookableServiceFilter = new BookableServiceFilter();
const viewportHelper = new ViewportHelper();

const apiEndPoints = {
  /*   Getters   */
  getIcsFile: 'api/appointment/getIcsFile',
  getOrder: 'api/order',
  getCanScheduleOrder: "api/order/schedulability",
  getDocuments: 'api/documents',
  getAppointments: 'api/appointment',
  getGeoCoordinates: 'api/geo/getGeoCodeViaAddress',
  getBookableServices: 'api/services',
  /*   Setters   */
  setAppointment: 'api/appointment/setAppointment',
  /*   Misc      */
  sendContactEmail: 'api/contact',
  bookServices: 'api/services/bookServices'
};
const stepper = {
  withAdditionalServices: 3,
  withoutAdditionalServices: 2,
};

export function setNotification(order: OrderDetailResponseOrder) {
  const isSelfSchedulable = order.allowSelfScheduling;
  const hasReachedSchedulableStatus = order.isSchedulingStatusReached;


  const isCancelled = order.currentStatus === 'Cancelled'
  const isCompleted = order.currentStatus === 'Fulfilled';
  const notDelivered = order.currentStatus === 'NotDelivered';
  const onWayToCustomer = order.currentStatus === 'OnWayToCustomer';
  const deliveryDelayed = false;            // TODO: Not Migrated
  const timeWindowNarrowed = false;      // TODO: Not Migrated
  const fixedDateChanged = false;          // TODO: Not Migrated
  const hasAppointment = order.deliveryDate;

  // Order created
  let descriptionKey = 'chooseAppointment';

  if (isCancelled) {
    descriptionKey = "orderCanceled";
  } else if (isCompleted) {
    descriptionKey = "orderComplete";
    // Unsuccessful delivery
  } else if (notDelivered) {
    descriptionKey = "deliveryUnsuccessful";
    // Delayed
  } else if (deliveryDelayed) {
    descriptionKey = "deliveryDelayed";
    // Time window narrowed
  } else if (timeWindowNarrowed) {
    descriptionKey = "timeWindowNarrowed";
    // Delivery Enroute
  } else if (onWayToCustomer) {
    descriptionKey = "deliveryEnRoute";
    // Date changed
  } else if (fixedDateChanged) {
    descriptionKey = "fixedDateChanged";
    // Date set
  } else if (hasAppointment) {
    descriptionKey = "fixedDate";
  } else if (!isSelfSchedulable) {
    descriptionKey = "orderNotSelfSchedulable";
  } else if (!hasReachedSchedulableStatus) {
    descriptionKey = "orderNotReachedSchedulableStatus";
    // Completed
  }

  return i18n.t('notificationBox.description.' + descriptionKey) as string;
}

function translateOrderState(tmsOrderStateDescription: string | null): string | null {
  switch(tmsOrderStateDescription) {
    case 'Auftrag zugestellt':
      return i18n.t('order.orderState.delivered').toString();
    case 'Ware erhalten':
      return i18n.t('order.orderState.goodsReceived').toString();
    case 'Auftrag erfasst':
      return i18n.t('order.orderState.created').toString();
    case 'Ausfuehrung unterbrochen':
      return i18n.t('order.orderState.deliveryStopped').toString();
    case 'Auftrag unterwegs':
      return i18n.t('order.orderState.inDelivery').toString();
    case 'Auftrag storniert':
      return i18n.t('order.orderState.cancelled').toString();
    case 'Problem im Depot':
      return i18n.t('order.orderState.problemInDepot').toString();
      default:
        return tmsOrderStateDescription;
  }
}

export function translateOrderStatus(orderStatus: string): string {
  switch(orderStatus) {
    case 'Fulfilled':
      return i18n.t('order.orderState.delivered').toString();
    case 'LoadedOnDistributionTruck':
    case 'Picked':
    case 'ReceivedAtHub':
    case 'GoodsAvailable':
    case 'GoodsLoaded':
    case 'LoadedOnDeliveryTruck':
      return i18n.t('order.orderState.goodsReceived').toString();
    case 'OrderCreated':
      return i18n.t('order.orderState.created').toString();
    case 'Clarification':
    case 'NotDelivered':
      return i18n.t('order.orderState.deliveryStopped').toString();
    case 'OnWayToCustomer':
      return i18n.t('order.orderState.inDelivery').toString();
    case 'Cancelled':
      return i18n.t('order.orderState.cancelled').toString();
    case 'PartiallyReceivedAtHub':
      return i18n.t('order.orderState.problemInDepot').toString();
    default:
      return orderStatus;
  }
}

const store = new Vuex.Store({
  state: {
    language: 'de',
    isLoggedIn: false,
    blockScroll: false,
    isStartup: true,
    hasNews: true,
    notificationDescription: '',
    service: {
      order: {} as IOrder,
      dayAmountForAppointment: 0,
      canMakeAppointment: false,
      hasAppointment: false,
      clientMapping: {},
      showCompensateEmissionsForClients: [],
      loginSuccessful: false
    } as IFundamentalData,
    order: {} as OrderDetailResponseOrder,
    customerAppointments: [],
    bookableServices: [],
    bookedServices: [],
    selectedAppointmentId: '',
    appointmentError: false,
    clientLatLng: {} as ILatLng,
    overlappingTimes: 0,
    minStickyBottom: 45,
    stickyBottom: 0,
    customErrorMessage: '',
    lastRequestDate: null,
  } as IState,

  getters: {
    getOrder(state: IState) {
      const orderStateDescription = state.service.order.orderStateDescription;
      state.service.order.orderStateDescription = translateOrderState(orderStateDescription);
      return state.service.order;
    },
    getDeliveryArticleCount(state: IState) {
      return featureFlags.BOOKING_RMD_USECLOUDENDPOINTS_SHIPMENT_COUNT ?  state.order.deliveryArticleCount : state.service.order.deliveryArticleCount;
    },
    getAppointments(state: IState) {
      return state.customerAppointments;
    },
    getOverlappingTimes(state: IState) {
      return state.overlappingTimes;
    },
    getService(state: IState) {
      return state.service;
    },
    getExternalOrderNumber(state: IState) {
      return state.service.order.externalOrderNumber;
    },
    getAddress(state: IState) {
      return state.service.order.address;
    },
    getMakingOrShowingAppointment(state: IState) {
     return state.service.canMakeAppointment || state.service.hasAppointment;
    },
    getCanMakeAppointment(state: IState) {
      return state.service.canMakeAppointment;
    },
    getHasAppointment(state: IState) {
      return state.service.hasAppointment;
    },
    getSelectedAppointment(state: IState) {
      if (!state.selectedAppointmentId) {
        return null;
      }

      const selectedAppointment =
        state.customerAppointments.filter((x: { id: string }) => x.id === state.selectedAppointmentId)[0];

      if (!selectedAppointment.timeslot) {
        selectedAppointment.timeslot = moment(selectedAppointment.start).format('HH:mm') +
          ' - ' +
          moment(selectedAppointment.end).format('HH:mm');

        // ENDCP-191 - Don't show 'Hodiny' in timeslot on confirmation page
        if (i18n.locale !== "cz")
          selectedAppointment.timeslot += ' ' + i18n.t('general.clock');
      }

      return selectedAppointment;
    },
    getEarliestAppointment(state: IState) {
      if (!state.customerAppointments || !state.customerAppointments.length) {
        return null;
      }

      return state.customerAppointments[0];
    },
    getStepperValue(state: IState) {
      if (state.bookableServices.length > 0) {
        return stepper.withAdditionalServices;
      } else {
        return stepper.withoutAdditionalServices;
      }
    },
    getLogoName(state: IState) {
      try {
        let clientKey = state.order.clientKey
        // TODO: clientKey TCHI is different in TMS and Cloud, when getting rid of legacy apis,
        //  we need to rename the logo icon as well
        if (clientKey === 'TCHI') {
          clientKey = 'Tchi';
        }
        return require(`@/assets/icons/clientlogos/${clientKey}.svg`);
      } catch (error) {
        return "";
      }
    },
    isIkeaClient(state: IState) {
      return state.order.clientDisplayName === 'IKEA'; 
    },
    getMappedClientName(state: IState) {
      for (const key in state.service.clientMapping) {
        const keyList = state.service.clientMapping[key].keyList;
        const clientKey = state.service.order.clientKey;
        
        if (keyList != null &&
          clientKey != null &&
          keyList.includes(clientKey)) {
          return state.service.clientMapping[key].name;
        }
      }
      return state.service.order.organizationUnitDescription;
    },
    showCompensateEmissions(state: IState) {
      if (!state.service.order.clientKey || state.language !== 'de') {
        return false;
      }
      return state.service.showCompensateEmissionsForClients.includes(state.service.order.clientKey);
    },
    allowTracking(){
      return (i18n.locale === "de" || i18n.locale === "pl" || i18n.locale === "cz");
    }
  },
  mutations: {
    LOCALIZATION(state: IState, language: string) {
      state.language = language;
    },
    LOGIN(state: IState) {
      state.isLoggedIn = true;
      state.isStartup = true;
      state.hasNews = true;
      state.notificationDescription = setNotification(state.order);
      state.lastRequestDate = new Date();
    },
    LOGOUT(state: IState) {
      state.isLoggedIn = false;
      state.appointmentError = false;
      state.lastRequestDate = null;
      featureFlags.setClientKey('');
    },
    NEW_REQUEST(state: IState) {
      state.lastRequestDate = new Date();
    },
    END_STARTUP(state: IState) {
      state.isStartup = false;
    },
    DISABLE_NEWS(state: IState) {
      state.hasNews = false;
    },
    SET_LEGACY_ORDER(state: IState, service: IFundamentalData) {
      state.service = service;
    },
    SET_ORDER(state: IState, value: OrderDetailResponseOrder) {
      state.order = value;
    },
    SET_ORDERSCHEDULABILITY(state: IState, schedulability: IOrderSchedulability) {
      state.service.canMakeAppointment = schedulability.canMakeAppointment;      
      state.service.dayAmountForAppointment = schedulability.dayAmountForAppointment;      
    },
    SET_CLIENTLNGLAT(state: IState, clientLngLat: ILatLng) {
      state.clientLatLng = clientLngLat;
    },
    SET_BOOKEDSERVICES(state: IState, bookedServices: IBookableService[]) {
      state.bookedServices = bookedServices;
    },
    BLOCK_SCROLL(state: IState, blockscroll: boolean) {
      const body = document.querySelector('body');
      if (body)
        blockscroll ? body.style.overflowY = 'hidden' : body.style.removeProperty('overflow-y');

      state.blockScroll = blockscroll;
    },
    SET_APPOINTMENTS(state: IState, appointments: Appointment[]) {
      state.customerAppointments = appointments;
    },
    SET_ALTERNATELANE(state: IState) {
      let max = 7;
      const appointments = state.customerAppointments;
      if (appointments.length > 1) {
        const selectedWeek = appointments[0].date;
        const appointmentsWithinWeek = calendarHelper.getMaxAlternateLane(appointments, appointments.length, 7, selectedWeek);
        const appointmentsInWeek = appointmentsWithinWeek.map(u => u.alternateLane);
        max = Math.max(...appointmentsInWeek) + 1;
      }
      state.overlappingTimes = max;
    },
    SET_BOOKABLESERVICES(state: IState, bookableServices: IBookableService[]) {
      state.bookableServices = bookableServiceFilter.getFilteredBookableServicesPackagingDisposal(bookableServices, state.service.order);
      state.bookableServices = bookableServiceFilter.getFilteredBookableServicesAdditionalService(state.bookableServices,state.service.order);
    },
    SET_SELECTED_APPOINTMENT(state: IState, value: string) {
      state.selectedAppointmentId = value;
    },
    SET_APPOINTMENT_ERROR(state: IState, value: boolean) {
      state.appointmentError = value;
    },
    SET_STICKYBOTTOM(state: IState, value?: number) {
      state.stickyBottom = value || state.minStickyBottom;
    },
    SET_CUSTOM_ERROR_MESSAGE(state: IState, value?: string) {
      state.customErrorMessage = value || '';
    },
    UPDATE_DELIVERY_TRUCK_MAP_DATA(state: IState, value?: DeliveryTruckMapData) {
      if (value) {
        if (!state.service.actualInformationTo) {
          state.service.actualInformationTo = {
            gpsLatitude: "",
            gpsLongitude: "",
            remainingOrders: "",
            driverName: "",
            estimatedArrivalTime: "",
            speed: ""
          };
        }

        state.service.actualInformationTo.gpsLatitude = value.gpsLatitude.toString();
        state.service.actualInformationTo.gpsLongitude = value.gpsLongitude.toString();
        state.service.actualInformationTo.remainingOrders = value.remainingOrders.toString();
      }
    },
    SET_NOTIFICATION_TEXT(state: IState, value: string){
      state.notificationDescription = value;
    },
    SET_ORDER_STATE_DESCRIPTION(state: IState, value: string) {
      state.service.order.orderStateDescription = value;
    }
  },

  actions: {
    requestCanMakeAppointment({ commit }) {
      commit('SET_ORDERSCHEDULABILITY', { canMakeAppointment: store.state.service.canMakeAppointment, dayAmountForAppointment: store.state.service.dayAmountForAppointment } as IOrderSchedulability);
      return null;
    },
    async requestOrderDetails({commit}, payload: IOrderRequest) {
          const getOrderPromise = payload.encryptionLoginType ? orderDetailService.GetByOrderNumberAndHash(payload) :
              orderDetailService.GetByOrderNumberAndPostalCode(payload);

          try {
              const response = await getOrderPromise;
              if (!response.isOk) {
                  return false;
              }
              commit('SET_LEGACY_ORDER', response.legacyData);
               const country = response.order!.contactData.orderAddressCountry;                  
               commit('SET_ORDER', response.order);
              
              const shortLocalCode = resolveTmsLocale(country == null ? '' : country);
              if (shortLocalCode !== 'de') {
                  i18n.locale = shortLocalCode;
              }
              if (shortLocalCode != '') {
                  commit('LOCALIZATION', shortLocalCode);
              }
              return true;
          } catch {
              return false;
          }
    },

    requestDocuments(_, payload: IDocumentsRequest): Promise<IDocument[]> {
      return legacyApiService.get(apiEndPoints.getDocuments, payload, "get_documents")
        .then((response: AxiosResponse<IDocumentData>) => {
          if (response.status !== 200) {
              return [];
            }
          return response.data.documents;
        });
    },

    requestDocument(_, payload: IDocumentRequest) {
      return legacyApiService.getBlob(payload.url, "open_document");
    },

    async requestIcsFile() {
      const order = store.state.service.order as IOrder;
      const address = order.address;
      const appointment = store.getters.getSelectedAppointment as Appointment;

      const date = calendarHelper.getFormattedDateForDisplay(appointment.start);
      const emailBody = i18n.t('appointment.summary.icsFileEmailBody', { name: `${address.firstName} ${address.surName}`, clientName: order.clientName, orderNumber: order.externalOrderNumber });
      const emailSubject = i18n.t('appointment.summary.icsFileEmailSubject', { clientName: order.clientName, orderNumber: order.externalOrderNumber, date })
      const icsFileData = {
        emailBody: emailBody,
        emailSubject: emailSubject,
        fromTime: calendarHelper.getFormattedDateTime(appointment.start),
        toTime: calendarHelper.getFormattedDateTime(appointment.end),
        address: `${address.street} ${address.houseNumber}, ${address.zipCode} ${address.location}`
      } as IIcsFileData;

    return await appointmentService.getAppointmentIcsFile(icsFileData);
    },

    async requestCustomerAppointments({ commit }) {
      const order = store.state.service.order;
      const appointmentParameters = {
        orderNumberUnique: order.internalOrderNumber,
        clientIdentifier: order.clientKey,
        countryCode: order.address.country === 'D' ? 'DE' : order.address.country,
        zipCode: order.address.zipCode,
        weight: order.weight,
        volume: order.volume,
        dayAmountForAppointment: store.state.service.dayAmountForAppointment,
        countryCodeFormat: order.address.country === 'D' ? 'DE' : order.address.country,
        backwards: false,
        orderState: order.orderState,
        createDate: order.createDate,
        locationEinsteuerung: order.locationEinsteuerung,
        locationCommissioning: order.locationCommissioning,
        location: order.location,
        extraOrder: order.extraOrder,
        internalOrderId: store.state.order.internalOrderId,
        startDate: new Date()
      } as IAppointmentsRequest;

      try
      {
        const appointments = await appointmentService.getAppointments(appointmentParameters);
        commit('SET_APPOINTMENTS', appointments);
        // Mutations are synchronous. The commit on SET_ALTERNATELANE will use the updated appointments (from SET_APPOINTMENTS).
        commit('SET_ALTERNATELANE');
        commit('SET_APPOINTMENT_ERROR', false);
        return null;
      }
      catch
      {
        //handling error
        commit('SET_APPOINTMENT_ERROR', true);
      }
    },

    async requestBookableServices({ commit }) {
      const data = {
        externalOrderNumber: store.state.service.order.externalOrderNumber,
        internalOrderId: store.state.order.internalOrderId
      } as IBookableServicesApi;

      try{
        const services = await bookableServicesService.GetBookableServices(data);
        commit('SET_BOOKABLESERVICES', services);
        return null;
      }
      catch
      {
        return null;
      }
    },

    async bookServices() {
      const curDateString = new Date(Date.now()).toUTCString();
      
      const data = {
        bookServicesRequest: this.state.order.internalOrderId
          ? ({
              internalOrderId: this.state.order.internalOrderId,
              serviceKeys: this.state.bookedServices.map(service => {
                return service.key;
                })
              } as IBookServicesRequest)
              : {} as IBookServicesRequest,
            clientKey: this.state.service.order.clientKey,
            externalOrderNumber: this.state.service.order.externalOrderNumber,
            language: i18n.locale,
            articlesOnOrder: store.getters.getDeliveryArticleCount,
            services: this.state.bookedServices.map((service) => {
          return {
            serviceName: service.key,
            servicePrice: {
              amount: service.sumPrice,
              currency: store.getters.getSelectedAppointment.currency
            } as IPrice,
            creationDateTime: calendarHelper.getFormattedDateTime(curDateString)
          } as IBookedService
        })
      } as IBookServicesApi;

      return await bookableServicesService.BookServices(data);
    },

    setCustomerAppointment() {

      const appointment = store.getters.getSelectedAppointment as Appointment;

      const confirmedAppointment = {
        appointment: appointment.date,
        fromTime: moment(appointment.start).format('HH:mm'),
        toTime: moment(appointment.end).format('HH:mm'),
        costs: appointment.price.toString(),
        currency: appointment.currency,
        internalOrderNumber: store.state.service.order.internalOrderNumber,
        earliestAppointment: store.getters.getEarliestAppointment.date,
        priceId: appointment.priceId,
        externalOrderNumber: store.state.service.order.externalOrderNumber,
        clientKey: store.state.service.order.clientKey,
        internalOrderId: store.state.order.internalOrderId,
        id: appointment.id,
      } as ISetAppointmentRequest;

      return appointmentService.setAppointments(confirmedAppointment);
    },

    async reloadOrderDetails() {
      const order = store.state.service.order;

      const request = {
        externalOrderNumber: order.externalOrderNumber,
        postcode: order.address.zipCode,
      } as IOrderRequest;

      await store.dispatch('requestOrderDetails', request)
        .then(() => {
          // Reset news
          store.state.hasNews = true;
        });
    },

    async sendContactEmail(_, payload: IContactRequest) {
      return contactFormService.sendContactFormData(payload);
    },

    async getGeoCoordinatesByAddress({ commit }) {
      const internalOrderId = store.state.order.internalOrderId;
      const response = await trackingService.GetCoordinates(internalOrderId);
      commit('SET_CLIENTLNGLAT', response);
      
      return null;
    },

    initStickyBottom({ commit }) {
      commit('SET_STICKYBOTTOM');
    },

    updateStickyBottom({ commit }) {
      const stickyBottomIsMin = store.state.stickyBottom == store.state.minStickyBottom;

      if (window.innerWidth <= 800) {
        // make sure that the sticky banner is reset to the correct bottom, if previously changed in desktop view
        if (!stickyBottomIsMin)
          commit('SET_STICKYBOTTOM');
      } else {
        const visibleFooterHeight = viewportHelper.getVisibleFooterHeight();

        if (visibleFooterHeight) {
          const newBottom = visibleFooterHeight + 10;
          if (newBottom > store.state.minStickyBottom)
            commit('SET_STICKYBOTTOM', newBottom);

        } else if (!stickyBottomIsMin) {
          commit('SET_STICKYBOTTOM');
        }
      }
    },

    setNewRequest({ commit }) {
      commit('NEW_REQUEST');
    },

    async getDeliveryTruckMapData({ commit }) {
        try {
          const response = await trackingService.getDeliveryTruckMapData(
            store.state.order.internalOrderId
          );
          commit("UPDATE_DELIVERY_TRUCK_MAP_DATA", response.data);
        } catch (error) {
          console.error("Failed to get delivery truck map data:", error);
        }
    }
  },
  plugins: [vuexLocalStorage.plugin]
});

export default store;
