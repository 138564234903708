import { apiService } from "@/services/ApiService";
import { AxiosResponse } from "axios";

export class ContactFormService {
  public async sendContactFormData(payload: IContactRequest) {
    const response: Promise<AxiosResponse> = apiService.post(
        "SelfScheduling/ContactRequest",
        payload,
        "contact_form_data_sent"
      );

    return response;
  }
}

export const contactFormService = new ContactFormService();
