import { AxiosResponse } from "axios";
import { apiService } from "./ApiService";
import { ILatLng } from "@/store/types/Order";
import { DeliveryTruckMapData } from "./types/TrackingMapData";
import { TrackingStatus } from "./types/TrackingStatus";

export class TrackingService {
  public async GetEstimatedTimeOfArrival(internalOrderId: string) {
    const response = await apiService.get(
      `SelfScheduling/eta/${internalOrderId}`,
      {},
      "get_eta"
    );

    return response.data;
  }

  public async GetCoordinates(internalOrderId: string) {
    const response: AxiosResponse<ILatLng> = await apiService.get(
        `SelfScheduling/geo-coordinates/${internalOrderId}`,
        {},
        "get_coordinates"
      );
    

    return response.data;
  }

  public async getDeliveryTruckMapData(internalOrderId: string) {
    return apiService.get<DeliveryTruckMapData>(
      `SelfScheduling/TourOrderDetail/${internalOrderId}`,
      {},
      "get_delivery_truck_map_data"
    );
  }

  public async GetTrackingStatus(internalOrderId: string)
  {
    return apiService.get<TrackingStatus[]>(
      `SelfScheduling/order-tracking-status/${internalOrderId}`,
      {},
      "get_order_tracking_status"
    );
  }
}

export const trackingService = new TrackingService();
