import { AxiosResponse } from "axios";
import { apiService} from "@/services/ApiService";
import { IBookableService, IBookableServicesApi, IBookServicesApi } from "@/store/types/Service";

export class BookableServiceService {

  public async GetBookableServices(
    payload: IBookableServicesApi
  ): Promise<AxiosResponse<IBookableService[]>> {
    
    //TODO: new feature flag for bookable services
    const response = await apiService.get(
        `SelfScheduling/BookableServices/${payload.internalOrderId}`,
        {},
        "bookable_services"
      );
    
    return response.data;
  }

  public async BookServices(payload: IBookServicesApi): Promise<void>
  {
    await apiService.post(`SelfScheduling/BookServices/${payload.bookServicesRequest.internalOrderId}`, { serviceKeys:  payload.bookServicesRequest.serviceKeys, articlesOnOrder: payload.articlesOnOrder }, 'book_services')
  }
}



export const bookableServicesService = new BookableServiceService();
